import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Text from '../components/atoms/Text';
import Flex from '../components/atoms/Flex';
import LinkButton from '../components/atoms/LinkButton';
import { graphql } from 'gatsby';
import PortfolioItem, { PortfolioData } from '../components/molecules/PortfolioItem';
import Heading from '../components/atoms/Heading';
import Box from '../components/atoms/Box';

const LargeBullet = styled(Heading)`
  margin: ${props => props.theme.space['3']} 0;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes['4xl']};
  &:before {
    content: '• ';
    color: ${props => props.theme.colors.green['600']};
  }
`;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { featured: { eq: true } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              mobile: childImageSharp {
                fixed(width: 200, height: 433) {
                  ...GatsbyImageSharpFixed
                }
              }
              web: childImageSharp {
                fixed(width: 400, height: 255) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            description
            responsibilities
            type
            date
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }: any) => (
  <Layout>
    <SEO title="Home" />
    <Text fontSize="lg">
      Howdy! My name is <b>Andreas</b> and I am a:
    </Text>
    <Box mt={6} mb={12}>
      <LargeBullet>Fullstack JavaScript Developer</LargeBullet>
      <LargeBullet>UI/UX Designer</LargeBullet>
      <LargeBullet>Huge Nerd</LargeBullet>
    </Box>
    <Flex mb={32}>
      <LinkButton to="https://github.com/eldjotnar" mr={6}>
        GitHub
      </LinkButton>
      <LinkButton to="https://www.linkedin.com/in/andreas-raduege-1a04a111a/" mr={6}>
        LinkedIn
      </LinkButton>
      <LinkButton to={`/Raduege_Resume.pdf`}>Resumé</LinkButton>
    </Flex>
    <Text mb={10}>Here&apos;s some stuff I&apos;m proud of.</Text>
    {data.allMarkdownRemark.edges.map((post: PortfolioData) => (
      <PortfolioItem
        key={post.node.frontmatter.title}
        title={post.node.frontmatter.title}
        featuredImage={post.node.frontmatter.featuredImage}
        responsibilities={post.node.frontmatter.responsibilities}
        description={post.node.frontmatter.description}
        slug={post.node.fields.slug}
        type={post.node.frontmatter.type}
      />
    ))}
  </Layout>
);

export default IndexPage;
