import React from 'react';
import Flex from '../atoms/Flex';
import IPhoneMockup from '../atoms/IPhoneMockup';
import Text from '../atoms/Text';
import Heading from '../atoms/Heading';
import LinkButton from '../atoms/LinkButton';
import ScreenMockup from '../atoms/ScreenMockup';

type Props = {
  title: string;
  featuredImage: any;
  responsibilities: string;
  description: string;
  slug: string;
  date?: string;
  type: 'web' | 'mobile';
};

export type PortfolioData = {
  node: {
    frontmatter: {
      title: string;
      featuredImage: any;
      description: string;
      responsibilities: string;
      date?: string;
      type: 'web' | 'mobile';
    };
    fields: {
      slug: string;
    };
  };
};

const PortfolioItem = ({
  title,
  featuredImage,
  responsibilities,
  description,
  slug,
  date,
  type,
}: Props) => {
  // console.log(featuredImage);
  const isMobile = type === 'mobile';
  const Frame = isMobile ? IPhoneMockup : ScreenMockup;
  return (
    <Flex mb={32} flexDirection={isMobile ? 'row' : 'column'}>
      <Frame image={featuredImage[type].fixed} />
      <Flex
        flexDirection="column"
        ml={isMobile ? 8 : 0}
        mt={isMobile ? 0 : 8}
        alignItems="flex-start"
      >
        <Heading fontSize="4xl" mb={2}>
          {title}
        </Heading>
        <Text color="green.500">{responsibilities}</Text>
        {date && (
          <Text mt={2} fontSize="sm">
            {new Date(date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}
          </Text>
        )}
        <Text my={8}>{description}</Text>
        <LinkButton to={slug}>Read More</LinkButton>
      </Flex>
    </Flex>
  );
};

export default PortfolioItem;
