import React from 'react';
import styled from 'styled-components';
import Image, { FixedObject } from 'gatsby-image';

const Container = styled.div`
  border-radius: 4px;
  background-color: #ffffff00;
  box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 10px #111;
  width: 400px;
  height: 225px;
  overflow: hidden;
`;

const Img = styled(Image)`
  background-color: red;
  position: relative;
  border-radius: 4px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
`;

const ScreenMockup = ({ image }: { image: FixedObject }) => (
  <Container>
    <Img fixed={image} />
  </Container>
);

export default ScreenMockup;
