import React from 'react';
import styled from 'styled-components';
import Image, { FixedObject } from 'gatsby-image';

type Props = {
  image: FixedObject;
};

const Container = styled.div`
  width: 200px;
  height: 433px;
  box-shadow: 0px 0px 0px 5px #1f1f1f, 0px 0px 0px 6px #191919, 0px 0px 0px 10px #111;
  border-radius: 20px;
  position: relative;
  background-color: #ffffff00;
  margin: 20px 0px;
`;

const Img = styled(Image)`
  border-radius: 20px;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  &:before {
    top: 0px;
    width: 56%;
    height: 15px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 20px 20px;
  }
`;

const IPhoneMockup = ({ image }: Props) => (
  <Container>
    <Img fixed={image} />
  </Container>
);

export default IPhoneMockup;
