import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import Text from './Text';

const Container = styled.a`
  background-color: hsl(221, 22%, 16%);
  text-decoration: none;
  color: white;
  border-bottom: 1px solid transparent;
  ${space}
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    border-bottom-color: ${props => props.theme.colors.green['600']};
  }
`;

const Label = styled(Text)`
  text-transform: uppercase;
  letter-spacing: ${props => props.theme.letterSpacings.widest};
`;

type Props = SpaceProps & {
  to: string;
  children: string;
};

const LinkButton = ({ to, children, ...props }: Props) => (
  <Container href={to} {...props}>
    <Label px={5} py={4}>
      {children}
    </Label>
  </Container>
);

export default LinkButton;
